import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import styles from './SiteInfo.module.css';

const SiteInfo = ({ data }) => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: false });

  return (
    <div ref={ref} className={styles.container}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 1 }}
        className={styles.imageContainer}
      >
        <img src={data.image} alt={data.title} className={styles.image} />
        <div className={styles.overlay}>
          <h2
            className={`${styles.title} ${data.id === 3 ? styles.titleSmall : ''}`}
          >
            {data.title}
          </h2>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 1 }}
        className={styles.textContainer}
      >
        {data.paragraphs.map((para, index) => (
          <p key={index} className={styles.paragraph}>
            {para}
          </p>
        ))}
      </motion.div>
    </div>
  );
};

export default SiteInfo;
