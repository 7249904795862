import { useState, useEffect } from 'react';

const useContactFormValidation = (formData) => {
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const validateForm = (formData) => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = "*";
        }

        if (!formData.email.trim()) {
            newErrors.email = "*";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)) {
            newErrors.email = "Invalid email address";
        }

        if (!formData.message.trim()) {
            newErrors.message = "*";
        }

        return newErrors;
    };

    useEffect(() => {
        const newErrors = validateForm(formData);
        setErrors(newErrors);
        setIsFormValid(Object.keys(newErrors).length === 0);
    }, [formData]);

    return { errors, isFormValid };
};

export default useContactFormValidation;
