import styles from './Footer.module.css'; 

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className={styles.footerContainer}>
      <p className={styles.footerText}>© {currentYear} CULTURAL ARCHITECTURE. ALL RIGHTS RESERVED.</p>
      <div className={styles.backToTopContainer}>
        <button className={styles.backToTop} onClick={scrollToTop}>BACK TO TOP</button>
      </div>
    </div>
  );
};

export default Footer;
