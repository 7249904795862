import logo from '../../images/logo.webp';
import styles from './Header.module.css';

const Header = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById('contact-form');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContent}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <div className={styles.contact} onClick={scrollToContact}>
          CONTACT US
        </div>
      </div>
    </header>
  );
};

export default Header;
