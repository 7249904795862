import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { motion, useInView } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import styles from './ContactForm.module.css';
import useContactFormValidation from '../../hooks/useContactFormValidation';

const formFields = [
    { id: "name", label: "NAME", type: "text" },
    { id: "email", label: "EMAIL", type: "email" },
    { id: "phone", label: "PHONE", type: "tel" },
    { id: "message", label: "MESSAGE", type: "textarea" },
];

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    const { errors, isFormValid } = useContactFormValidation(formData);
    const ref = useRef(null);
    const inView = useInView(ref, { once: false });
    const isDesktop = useMediaQuery({ minWidth: 768 });

    const infoItems = [
        "Cultural Architecture works with a variety of small to medium-sized organizations, including automotive, military, dental and medical practices as well as retail and salons.",
        `If you’re interested in elevating your company culture and growing your bottom line, please fill out the form ${isDesktop ? 'on the right' : 'below'} to start the conversation.`
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const userId = process.env.REACT_APP_EMAILJS_USER_ID;

        emailjs.send(serviceId, templateId, formData, userId)
            .then((response) => {
                setResponseMessage("Message sent successfully!");
                setIsSubmitting(false);
                setTimeout(() => {
                    setResponseMessage("");
                    setFormData({ name: "", email: "", phone: "", message: "" });
                }, 5000);
            }, (error) => {
                setResponseMessage("Failed to send message. Please try again later.");
                setIsSubmitting(false);
                setTimeout(() => {
                    setResponseMessage("");
                }, 5000);
            });
    };

    return (
        <div id='contact-form' ref={ref} className={styles.container}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
                className={styles.infoContainer}
            >
                <h2 className={styles.infoTitle}>Learn More About Our Training</h2>
                {infoItems.map((item, index) => (
                    <p key={index} className={styles.infoText}>{item}</p>
                ))}
            </motion.div>
            <motion.form 
                onSubmit={handleSubmit} 
                initial={{ opacity: 0 }}
                animate={{ opacity: inView ? 1 : 0 }}
                transition={{ duration: 1 }}
                className={styles.contactForm}
            >
                {formFields.map(field => (
                    <div key={field.id} className={styles.formGroup}>
                        <div className={styles.labelWrapper}>
                            <label htmlFor={field.id} className={styles.label}>{field.label}</label>
                            {errors[field.id] && <span className={styles.error}>{errors[field.id]}</span>}
                        </div>
                        {field.type === "textarea" ? (
                            <textarea
                                id={field.id}
                                name={field.id}
                                value={formData[field.id]}
                                onChange={handleChange}
                                className={styles.inputField}
                                placeholder={`Enter your ${field.label.toLowerCase()}`}
                                style={{ height: '5rem' }}
                            />
                        ) : (
                            <input
                                type={field.type}
                                id={field.id}
                                name={field.id}
                                value={formData[field.id]}
                                onChange={handleChange}
                                className={styles.inputField}
                                placeholder={`Enter your ${field.label.toLowerCase()}`}
                            />
                        )}
                    </div>
                ))}
                {responseMessage && <p className={styles.responseMessage}>{responseMessage}</p>}
                <button type="submit" disabled={isSubmitting || !isFormValid} className={styles.submitButton}>Send</button>
            </motion.form>
        </div>
    );
};

export default ContactForm;
