import pitCrew from '../images/pitCrew.webp';
import ferrari from '../images/ferrari.webp';
import thundercat from '../images/thundercat.webp';
import mercedes from '../images/mercedes.webp';

export const siteData = [
    {
      id: 1,
      image: pitCrew,
      title: 'Build the culture you want.',
      paragraphs: [
        'Cultural Architecture offers a powerful educational experience that helps you and your team create the right culture for your organization.'
      ]
    },
    {
      id: 2,
      image: ferrari,
      title: 'Improve productivity and profits.',
      paragraphs: [
        'Transform your business by recognizing the potential of your people. Our life-changing leadership training and structured curriculum provide valuable tools for fostering growth and agency for every employee.',
        'Cultural Architecture delivers lasting, measurable improvements in leadership, workplace satisfaction and morale.',
        'We also advance an ownership mentality for all team members boosting performance and teamwork while reducing free riding, turnover, absenteeism and infighting.'
      ]
    },
    {
      id: 3,
      image: thundercat,
      title: 'Adopt new ways of communicating.',
      paragraphs: [
        'Our paradigm-shifting programs leave an indelible mark on the hearts and minds of those who participate. Learn how to cultivate real leadership and effective communication as critical skill sets that can be quantified and built on.',
        'When you implement Cultural Architecture’s best practices, the result is a thriving, energizing and supportive workplace that people want to be a part of because it’s authentic.',
        'Communication will focus on what’s important and vital. Team members will identify opportunities and leverage strengths with a new sense of purpose and satisfaction.',
        'You will quickly create a culture with less gossip, fewer internal politics and greater appreciation for others.'
      ]
    },
    {
      id: 4,
      image: mercedes,
      title: 'Educated employees are empowered leaders.',
      paragraphs: [
        'For more than 20 years, at Cultural Architecture we’ve developed and delivered comprehensive, proprietary leadership tools and models as part of our passion for redefining and expanding leadership company-wide for all owners and employees.'
      ]
    }
  ];
  