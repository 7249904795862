import Carousel from "../components/Carousel/Carousel";
import SiteInfo from "../components/SiteInfo/SiteInfo";
import { siteData } from "../data/SiteData";
import ContactForm from "../components/ContactForm/ContactForm";
import styles from './Home.module.css';

const Home = () => {
  return (
    <div className={styles.homeContainer}>
      <Carousel />
      {siteData.map((data) => (
        <SiteInfo key={data.id} data={data} />
      ))}
      <ContactForm />
    </div>
  );
};

export default Home;
