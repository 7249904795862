import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './Carousel.module.css'; 

import carousel1 from '../../images/carousel1.webp';
import carousel2 from '../../images/carousel2.webp';
import carousel3 from '../../images/carousel3.webp';
import carousel4 from '../../images/carousel4.webp';
import carousel5 from '../../images/carousel5.webp';

const images = [carousel1, carousel2, carousel3, carousel4, carousel5];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); 
    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className={styles.carousel}>
      <button className={`${styles.carouselButton} ${styles.prev}`} onClick={handlePrev}>
        <PrevArrow />
      </button>
      <div className={styles.imageContainer}>
        <AnimatePresence initial={false}>
          <motion.img
            key={images[currentIndex]}
            src={images[currentIndex]}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={styles.carouselImage}
          />
        </AnimatePresence>
      </div>
      <button className={`${styles.carouselButton} ${styles.next}`} onClick={handleNext}>
        <NextArrow />
      </button>
    </div>
  );
};

const PrevArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 19l-7-7 7-7" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const NextArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 5l7 7-7 7" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default Carousel;
